import Grid from "@mui/material/Grid";
import React from "react";
import { Typography, Box, Container } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import GeneralBtn from "../Button/GeneralBtn";

import { makeStyles } from "@material-ui/core/styles";

// import blueIcon from "../../assets/icons/blueIcon.png";
// import greenIcon from "../../assets/icons/greenIcon.png";

const blueIcon =
  "https://drive.google.com/uc?export=view&id=159ZHUmC8GXQK38mfw2tTsE3IwG8Hz75k";
const greenIcon =
  "https://drive.google.com/uc?export=view&id=1PHSKtm7vqeYxuFK43CKIIwD7_fAlJ_kG";
const balls =
  "https://drive.google.com/uc?export=view&id=1WFcV0fRsk9pg350cb6tbLGcxYOX6E_eX";
const circle =
  "https://drive.google.com/uc?export=view&id=1_m04ODqP62j48jQM_JmpzVRG-Tk3LXpk";
const halfBall =
  "https://drive.google.com/uc?export=view&id=1QAqROpj6UME_68kXv3xJATcCmw9kt-Rv";

const useStyles = makeStyles({
  root: {
    pt: 5,
  },
  typo: {
    fontSize: {
      lg: "30px",
      md: "30px",
      sm: 20,
      xs: 12,
    },
    fontWeight: 700,
  },
  headingStroke: {
    " -webkit-text-stroke": "1px #FDA837",
    " -webkit-text-fill-color": "transparent",
    fontWeight: "bold",
  },
  workHeaderBg: {
    clipPath: " polygon(0 0, 100% 0%, 100% 50%, 0 70%)",

    background: "#8300F8",
    height: "20vh",
  },
  mobileImg: {
    "@media (max-width: 1440px)": {
      height: "400px !important",
    },
    "@media (max-width: 1024px)": {
      height: "500px !important",
    },
    "@media (max-width: 768px)": {
      height: "600px !important",
    },
    "@media (max-width: 535px)": {
      height: "650 !important",
    },
  },
});

export default function ProjectDetail(props) {
  let navigate = useNavigate();
  const HeaderFun = () => {
    navigate("/ContactUs");
  };

  const classes = useStyles();
  return (
    <>
      <Box className={classes.workHeaderBg} />
      <Container maxWidth="xl" sx={{ margin: "0px !important", paddingBottom: "15px" }}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Box sx={{ display: "flex" }}>
              <Box
                component="img"
                src={balls}
                sx={{
                  paddingTop: "30px",
                  transform: "translate(-25px, -105px)",
                  height: { xs: 320, sm: 340, md: 370, lg: 420 },
                }}loading="lazy" 
              />
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "flex",
                    md: "flex",
                    lg: "flex",
                  },
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                <Box marginTop={7}>
                  <Typography
                    className={classes.typo}
                    color={"#FDA837"}
                    sx={{
                      fontSize: {
                        lg: 70,
                        md: 47,
                        sm: 45,
                        xs: 30,
                      },
                      lineHeight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {props.arr.title1}
                    <Typography
                      color={"#FDA837"}
                      sx={{
                        fontSize: {
                          lg: 70,
                          md: 67,
                          sm: 45,
                          xs: 30,
                        },
                      }}
                      className={classes.headingStroke}
                    >
                      {props.arr.title2}
                    </Typography>
                  </Typography>
                  <Typography color="#777777">
                    {props.arr.description}
                  </Typography>
                  <Box
                    sx={{
                      ml: { lg: "5px", md: "5px", sm: "5px", xs: "5px" },
                      mt: "15px",
                    }}
                  >
                    <GeneralBtn
                      title="Contact us"
                      paddingL="15px"
                      paddingR="0px"
                      width="175px"
                      marginL="10px"
                      fun={HeaderFun}
                    />
                  </Box>
                </Box>
                <Box
                  component="img"
                  src={props.arr.mobile}
                  sx={{
                    height: { xs: 300, sm: 300, md: 450, lg: 500 },
                    transform: {
                      sm: "translate(20px, 30px)",
                      md: "translate(200px, 0px)",
                      lg: "translate(300px, 0px)",
                      xxl: "translate(400px, 0px)",
                    },
                    textAlign: "center",
                    paddingTop: { xs: "28px" },
                  }}loading="lazy" 
                />
              </Box>
              <Box
                component="img"
                src={circle}
                sx={{
                  height: { xs: 50, sm: 40, md: 70, lg: 80 },
                  transform: {
                    lg: "translate(340px, 30px)",
                    md: "translate(250px, 30px)",
                    sm: "translate(20px, 40px)",
                    xs: "translate(0px, 0px)",
                  },
                  right: 0,
                }}loading="lazy" 
              />
              <Box
                component="img"
                src={halfBall}
                sx={{
                  height: { xs: 35, sm: 40, md: 50, lg: 50 },
                  transform: {
                    lg: "translate(0px, -60px)",
                    md: "translate(0px, -60px)",
                    sm: "translate(0px, -60px)",
                    xs: "translate(0px, -60px)",
                  },
                  position: "absolute",
                  right: 0,
                }}loading="lazy" 
              />
            </Box>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12} >
            <img alt="" component="image" src={img1} loading="lazy" />
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                paddingTop: { xs: "20px", sm: "40px", md: "60px" },
                paddingLeft: { xs: "0px", sm: "0px", md: "60px", lg: "60px" },
              }}
              aria-label="contacts"
            >
              <Box display={"flex"}>
                <img
                  src={blueIcon}
                  height={58}
                  style={{ transform: "translate(-33px, -10px)" }}
                  alt=""
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      xs: "22px",
                      sm: "24px",
                      md: "26px",
                      lg: "33px",
                    },
                    fontWeight: { xs: "600", sm: "700", md: "700", lg: "700" },
                    margin: "0px 0px 19px 0px",
                    transform: "translate(-56px, 5px)",
                    fontFamily: "Montserrat",
                  }}
                >
                  FEATURES
                </Typography>
              </Box>
              {props.arr.features?.split(/\r?\n/).map((item, index) => {
                return (
                  <ListItem disablePadding disableGutters key={index}>
                    <Box
                      sx={{
                        background: "#8300f8",
                        height: "13px",
                        width: "13px",
                        border: "2px solid orange",
                        borderRadius: "40px",
                        padding: "0px 5px 0px 5px",
                      }}
                    />
                    <ListItemText
                      primary={item}
                      sx={{
                        margin: "0px 0px 0px 8px",
                        fontFamily: "Montserrat",
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                paddingLeft: { xs: "0px", sm: "0px", md: "60px", lg: "60px" },
              }}
              aria-label="contacts"
            >
              <Box display={"flex"}>
                <img
                  src={greenIcon}
                  height={58}
                  style={{ transform: "translate(-33px, -10px)" }}
                  alt="" loading="lazy" 
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "24px",
                      md: "26px",
                      lg: "33px",
                    },
                    fontWeight: { xs: "600", sm: "700", md: "700", lg: "700" },
                    margin: "0px 0px 19px 0px",
                    fontFamily: "Montserrat",
                    transform: "translate(-56px, 5px)",
                  }}
                >
                  ADVANTAGES
                </Typography>
              </Box>

              {props.arr.advantages?.split(/\r?\n/).map((item, index) => {
                return (
                  <ListItem disablePadding disableGutters key={index}>
                    <Box
                      sx={{
                        background: "#8300f8",
                        height: "13px",
                        width: "13px",
                        border: "2px solid orange",
                        borderRadius: "40px",
                        padding: "0px 5px 0px 5px",
                        fontFamily: "Montserrat",
                      }}
                    />
                    <ListItemText
                      primary={item}
                      sx={{
                        margin: "0px 0px 0px 8px",
                        fontFamily: "Montserrat",
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
