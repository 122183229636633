import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Card4(props) {
  return (
    <Box sx={{ textAlign: "center", padding: "50px 0px 50px 0px" }}>
      <Typography
        variant="h3"
        sx={{
          margin: "40px 0px 80px 0px",
          fontWeight: "bold",
          fontSize: { xs: "30px", sm: "35px", md: "40px", lg: "45px" },
        }}
      >
        WHAT WE DO
      </Typography>
      <Grid
        sx={{ flexGrow: 1, justifyContent: "center" }}
        container
        spacing={2}
      >
        {props.cards.map((item, index) => {
          return (
            <Grid
              key={index}
              item
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                padding: { xs: "6px", sm: "8px", md: "10px", lg: "22px" },
                margin: "10px 15px 10px 15px",
              }}
            >
              <Box
                sx={{
                  color: "orange",
                  marginTop: { xs: "25px", sm: "35px", md: "45px", lg: "55px" },
                }}
              >
                <img
                  fontSize={{ xs: "45px", sm: "55px", md: "65px", lg: "75px" }}
                  src={item.icon}
                  height={66}
                  alt=""
                  loading="lazy"
                />
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "14px", sm: "17px", md: "20px", lg: "23px" },
                  color: "#666362",
                  margin: { xs: "15px", sm: "20px", md: "25px", lg: "30px" },
                  maxWidth: {
                    xs: "100px",
                    sm: "130px",
                    md: "140px",
                    lg: "150px",
                  },
                }}
              >
                {item.title}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Card4;
