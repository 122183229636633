import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { API } from "../../api/index.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CallIcon from "@mui/icons-material/Call";
import { location } from "../../assets/icons/allicons";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GeneralBtn from "../Button/GeneralBtn";
import { msg } from "../../assets/icons/allicons";
import TextInput from '../Utils/TextInput'

const useStyles = makeStyles(() => ({
  headeing1: {
    textAlign: "center",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridDirection: {
    "@media (max-width: 599px)": {
      direction: "column-reverse",
    },
  },
  noborder: {
    border: "none",
    outline: "none",
  },
  contact: {
    "@media (min-width: 1500px)": {
      width: "1450px",
      margin: "0 auto",
    },
    "@media (min-width: 2000px)": {
      width: "80%",
    },
  },
}));

const initialValues = {
  name: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
});

const ContactUs = (props) => {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [policy, setPolicy] = React.useState({});

  const getContacts = () => {
    API.getAllContacts().then((res) => {
      setData(res.data?.data);
    });
  }

  const getPolicies = () => {
    API.policies().then((res) => {
      setPolicy(res.data?.data[0])
    })
  }

  function onSubmit(values, { resetForm }) {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("message", values.message);
    API.sendMail(formData).then(() => resetForm());
  }

  useEffect(() => {
    getPolicies();
    getContacts();
  }, []);

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      {props.title !== "No" && (
        <Box sx={{ pt: { md: 5, xs: 3 } }}>
          <Typography
            className={classes.headeing1}
            sx={{
              fontSize: { lg: "40px", md: "30px", xs: "30px" },
              fontWeight: "bold",
            }}
          >
            CONTACT US{" "}
          </Typography>
          <Typography
            className={classes.headeing1}
            sx={{
              color: "#8F8F8F",
              fontSize: { lg: "17px", md: "15px", sm: "12px", xs: "12px" },
              px: "30px",
            }}
          >
            We’d love to discuss your ideas and look forward to bring them in
            real life.
          </Typography>
        </Box>
      )}
      <Container maxWidth='xl'>
        <Box sx={{ px:{md:5,lg: 5}, pt: { lg: "80px", xs: "30px" } }}
          className={classes.contact}>
          <Card sx={{ boxShadow: "2px 8px 15px #888888", borderRadius: "40px", position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                width: { lg: "5px" },
                height: { lg: "5px" },
                borderRadius: "50px",
                background: "#b362fc",
                top: { lg: "10px" },
                right: "30px",
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { lg: "15px" },
                height: { lg: "15px" },
                borderRadius: "50px",
                background: "#f4cd8b",
                top: { lg: "30px" },
                right: "50px",
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { lg: "20px" },
                height: { lg: "20px" },
                borderRadius: "50px",
                background: "#b362fc",
                top: { lg: "50px" },
                right: "70px",
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { lg: "100px", xs: "70px" },
                height: { lg: "100px", xs: "70px" },
                borderRadius: "50px",
                background: "#c56a89",
                top: { lg: "14px", md: "14px", sm: "14px", xs: "62%" },
                left: { lg: "34%", md: "34%", sm: "30%", xs: "85%" },
                zIndex: 2,
                opacity: 0.8,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { lg: "40px", xs: "20px" },
                height: { lg: "40px", xs: "20px" },
                borderRadius: "50px",
                background: "#f4cd8a",
                bottom: { lg: "98px", md: "65px", sm: "60px", xs: "49%" },
                display: { lg: "block", md: "block", sm: "block", xs: "none" },
                right: "9px",
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { lg: "100px", xs: "60px" },
                height: { lg: "100px", xs: "60px" },
                borderRadius: "50px",
                background: "#e1c2fe",
                bottom: { lg: "5px", md: "5px", sm: "5px", xs: "43%" },
                right: "10px",
                display: { lg: "block", md: "block", sm: "block", xs: "none" },
              }}
            />
            <Grid container spacing={5} className={classes.gridDirection}>
              <Grid
                item
                md={5}
                sm={5}
                xs={12}
                order={{ lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <Card
                  sx={{
                    backgroundColor: "#8300F8",
                    padding: { lg: "20px", md: "15px", sm: "7px", xs: "0px" },
                    height: "100%",
                    borderRadius: "40px",
                    position: "relative",
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: { lg: 25, md: 20, sm: 15, xs: 12 },
                        pt: "4px",
                        fontWeight: "600",
                      }}
                      color="white"
                    >
                      Contact Information
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        color: "#EFAD42",
                        pt: { lg: 2, md: 5, sm: 5, sx: 5 },
                      }}
                    >
                      <CallIcon fontSize="large" />

                      <Typography
                        sx={{
                          pl: { lg: 2, md: 2, sm: 1, xs: 0.5 },
                          color: "white",
                          fontSize: {
                            lg: "18px",
                            md: "17px",
                            sm: "14px",
                            xs: "15px",
                          },
                        }}
                      >
                        {data?.number}
                      </Typography>
                    </Box>
                  </CardActions>
                  <CardActions>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        color: "#EFAD42",
                        pt: { lg: 2, md: 6, sm: 6, sx: 6 },
                      }}
                    >
                      {/* <MailOutlineIcon fontSize="large" /> */}
                      <img src={msg} alt="" loading="lazy" />

                      <Typography
                        sx={{
                          pl: { lg: 2.8, md: 2.7, sm: 1.5, xs: 1.5 },
                          color: "white",
                          fontSize: {
                            lg: "18px",
                            md: "17px",
                            sm: "14px",
                            xs: "15px",
                          },
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.email}
                      </Typography>
                    </Box>
                  </CardActions>
                  <CardActions>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        color: "#EFAD42",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: 20, sm: 20, md: 20 },
                          height: { xs: 6, sm: 20, md: 20 },
                          mr: { md: 1.5, sm: 2, xs: 2 },
                        }}
                      >
                        <img src={location} style={{ objectFit: "fit" }} alt="" loading="lazy" />
                      </Box>

                      <Typography
                        sx={{
                          pl: { lg: 2, md: 2, sm: 1, xs: 0.5 },
                          pt: "15px",
                          color: "white",
                          fontSize: {
                            lg: "18px",
                            md: "17px",
                            sm: "14px",
                            xs: "15px",
                          },
                          zIndex: 2,
                        }}
                      >
                        {data?.address1}
                      </Typography>
                    </Box>
                  </CardActions>
                  {data?.address2 && (
                    <CardActions>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "#EFAD42",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: 20, sm: 20, md: 20 },
                            height: { xs: 6, sm: 20, md: 20 },
                            mr: { md: 1.5, sm: 2, xs: 2 },
                          }}
                        >
                          <img src={location} style={{ objectFit: "fit" }} alt="" loading="lazy" />
                        </Box>
                        <Typography
                          sx={{
                            pl: { lg: 2, md: 2, sm: 1, xs: 0.5 },
                            pt: "15px",
                            color: "white",
                            fontSize: {
                              lg: "18px",
                              md: "17px",
                              sm: "14px",
                              xs: "15px",
                            },
                            zIndex: 2,
                          }}
                        >
                          {data?.address2}
                        </Typography>
                      </Box>
                    </CardActions>
                  )}
                  <CardActions>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        marginTop: "60px",
                        mb: { lg: "-10px", md: "-10px" },
                        pl: { xs: "5px" },
                      }}
                    >
                      <Box
                        sx={{
                          mx: {
                            lg: "10px",
                            md: "10px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        <FacebookIcon sx={{ cursor: 'pointer' }} onClick={() => window.open(data?.facebook)} />
                      </Box>
                      <Box
                        sx={{
                          mx: {
                            lg: "10px",
                            md: "10px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        <InstagramIcon sx={{ cursor: 'pointer' }} onClick={() => window.open(data?.instagram)} />
                      </Box>
                      <Box
                        sx={{
                          mx: {
                            lg: "10px",
                            md: "10px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        <YouTubeIcon sx={{ cursor: 'pointer' }} onClick={() => window.open(data?.youtube)} />
                      </Box>
                    </Box>
                  </CardActions>
                  <Box
                    sx={{
                      position: "absolute",
                      width: { lg: "30px", md: "40px", sm: "30px", xs: "20px" },
                      height: {
                        lg: "30px",
                        md: "40px",
                        sm: "30px",
                        xs: "20px",
                      },
                      borderRadius: "50%",
                      background: "#c56a88",
                      top: { lg: "120px", md: "100px", sm: "80px", xs: "96px" },
                      right: { lg: "30px", md: "30px", sm: "24px", xs: "40px" },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      width: { lg: "50px", md: "30px", sm: "30px", xs: "50px" },
                      height: {
                        lg: "50px",
                        md: "30px",
                        sm: "30px",
                        xs: "50px",
                      },
                      borderRadius: "50%",
                      background: "#c76e82",
                      bottom: {
                        lg: "150px",
                        md: "140px",
                        sm: "138px",
                        xs: "130px",
                      },
                      right: { lg: "50px", md: "40px", sm: "20px", xs: "30px" },
                      opacity: 0.8,
                      zIndex: 1,
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      width: {
                        lg: "150px",
                        md: "130px",
                        sm: "100px",
                        xs: "150px",
                      },
                      height: {
                        lg: "150px",
                        md: "130px",
                        sm: "100px",
                        xs: "150px",
                      },
                      borderRadius: "50%",
                      background: "#6400bd",
                      bottom: {
                        lg: "10px",
                        md: "25px",
                        sm: "50px",
                        xs: "10px",
                      },
                      right: { lg: "16px", md: "5px", sm: "5px", xs: "20px" },
                    }}
                  />
                </Card>
              </Grid>

              <Grid
                item
                md={7}
                sm={7}
                xs={12}
                order={{ lg: 2, md: 2, sm: 2, xs: 1 }}
                sx={{ pl: { lg: 5, md: 3, sm: 3, xs: 10 }, pr: 3, pt: 1 }}
              >
                <Box sx={{ pl: { xs: "20px" } }}>
                  <Typography
                    sx={{
                      fontSize: {
                        md: "34px",
                        lg: "34px",
                        sm: "25px",
                        xs: "20px",
                      },
                      textAlign: "left",
                      fontWeight: "bold",
                      pt: "48px",
                    }}
                  >
                    Lets Talk
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, textAlign: "left", color: "#8F8F8F" }}
                  >
                    Please help us to respond better to your query by filling
                    out this form.
                  </Typography>

                  <Box component={'form'} onSubmit={formik.handleSubmit} pt={3}>
                    <TextInput formik={formik} name="name" label="Enter Your Name" />
                    <TextInput formik={formik} name="email" label="Enter Valid Email" />
                    <TextInput formik={formik} name="message" label="Message" multiline rows={4} />
                    <Typography
                      sx={{
                        fontSize: 14,
                        textAlign: "left",
                        color: "#8F8F8F",
                      }}
                    >
                      By sending this form I confirm that I have read and
                      accept <br />
                      intellectsoft <a target={"_blank"} rel="noreferrer" href={policy.privacy_policy}>Privacy Policy</a>
                    </Typography>
                    <Box
                      sx={{
                        pb: { lg: 3, sm: 3, xs: 3 },
                        mt: "10px",
                      }}
                    >
                      <GeneralBtn
                        title="Send"
                        paddingL="50px"
                        paddingR="10px"
                        marginL="16"
                        type="submit"
                        fun={""}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default ContactUs;
