import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { discussProjectBg } from "../../assets/images/allimages";
import { Typography } from "@mui/material";
import GeneralBtn from "../Button/GeneralBtn";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  bg: {
    backgroundImage: `URl(${discussProjectBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "@media (max-width: 320px)": {
      backgroundSize: "100% 180px",
    },
    "@media (max-width: 768px)": {
      backgroundSize: "100% 200px",
    },
  },
}));

export default function DiscussProject() {
  let navigate = useNavigate();
  const HeaderFun = () => {
    navigate("/ContactUs");
  };
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.bg}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          pt: { xs: 3, sm: 4, md: 4, lg: 10 },
          pb: { xs: 3, sm: 4, md: 4, lg: 5 },
        }}
      >
        <Box
          sx={{
            pr: { xl: 4, lg: 1, md: 3, sm: 3, xs: 0 },
            pl: { md: 9, sm: 6, xs: 4 },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: { lg: 42, md: 36, sm: 22, xs: 16 },
              fontWeight: "bold",
              lineHeight: 1.2,
            }}
          >
            Interested in
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 42, md: 36, sm: 22, xs: 16 },
                fontWeight: "bold",
                lineHeight: 1.2,
              }}
              component={"span"}
            >
              &nbsp;discussing a project?
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            pr: { xl: 7, lg: 4, md: 4, sm: 0, xs: 0 },
            pl: { md: 9, sm: 4, xs: 4 },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: { lg: 36, md: 30, sm: 22, xs: 14 },
              lineHeight: 1.2,
            }}
          >
            Let's build
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 36, md: 30, sm: 22, xs: 14 },
                lineHeight: 1.2,
              }}
              component={"span"}
            >
              &nbsp;something great
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ pt: { xs: 4, lg: 9 } }}>
          <GeneralBtn
            title="Get Started"
            paddingL="15px"
            paddingR="0px"
            width="175px"
            marginL="10px"
            fun={HeaderFun}
          />
        </Box>
      </Box>
    </>
  );
}
