// import call from "../icons/call.png";
// import fb from "../icons/fb.png";
// import insta from "../icons/insta.png";
// import youtube from "../icons/youtube.png";
// import location from "../icons/location.png";
// import msg from "../icons/msg.png";
// import cardpic1 from "../icons/Group1.png";
// import cardpic2 from "../icons/Group2.png";
// import cardpic3 from "../icons/Group3.png";
// import cardpic4 from "../icons/Group4.png";
// import cardpic5 from "../icons/Group5.png";

const call = "https://drive.google.com/uc?export=view&id=1mFJknZ2c5lvFwxv5GwxfvC__yfvZWwqt";
const fb = "https://drive.google.com/uc?export=view&id=1desmN8zHxXGID-PaHKnVjFMs5ydcwTKB";
const insta = "https://drive.google.com/uc?export=view&id=1-bFKp17ekLNHrFUVytOw13JaisfqCRxD";
const youtube = "https://drive.google.com/uc?export=view&id=1GAxH9xfEmSeEtC0R6xy44wUOTHjp8Kc_";
const location = "https://drive.google.com/uc?export=view&id=1okmE8pMKE-sNZuShsxMwwRsxstq4fBnb";
const msg = "https://drive.google.com/uc?export=view&id=12c66K7iMd-tOLHOAumri2qIFunHtkZJd";


export {
  call,
  fb,
  insta,
  youtube,
  location,
  msg,
  // cardpic1,
  // cardpic2,
  // cardpic3,
  // cardpic4,
  // cardpic5,
};
