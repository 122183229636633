import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography, Link, InputAdornment, OutlinedInput, Container } from "@mui/material";
import { API } from "../../api";
import { makeStyles } from "@material-ui/core/styles";
import { call, fb, insta, location, msg } from "../../assets/icons/allicons";
import linkdein from "../../assets/images/linkdein3.png";
import twitter from "../../assets/images/twitter2.png";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles({
  setFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  subheadingL: {
    fontFamily: "'Montserrat', sans-serif",
    // background:"yellow",
    // color:"black !important",
    "@media (max-width: 1440px)": {
      fontSize: "16px !important",
      padding: "4px 0",
    },
    "@media (max-width: 1024px)": {
      fontSize: "18px !important",
      padding: "10px 0",
    },
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
      padding: "2px 0",
    },
    "@media (max-width: 490px)": {
      fontSize: "14px !important",
      padding: 0,
    },
    "@media (max-width: 320px)": {
      fontSize: "10px !important",
      padding: 0,
    },
  },
  subheadingR: {
    // color:"black !important",
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width: 1440px)": {
      fontSize: "16px !important",
      paddingleft: "10px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "18px !important",
      paddingleft: "10px",
    },
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
      paddingLeft: "5px",
    },
    "@media (max-width: 490px)": {
      fontSize: "14px !important",
      paddingLeft: "2px",
    },
    "@media (max-width: 320px)": {
      fontSize: "8px !important",
      paddingLeft: "2px",
    },
  },

  mediaIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 350px)": {
      justifyContent: "space-around",
    },
  },
  main: {
    "@media (max-width: 420px)": {
      marginLeft: "-60px",
      display: "flex",
      justifyContent: "flex-start !important",
      alignItems: "flex-start !important",
      // background:"yellow"
    },
  },
  links: {
    // wordBreak: "break-all",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    "@media (min-width: 1440px)": {
      fontSize: "13px !important",
    },
    "@media (max-width: 1440px)": {
      fontSize: "14px !important",
    },
    "@media (max-width: 1200px)": {
      fontSize: "10px !important",
    },
    "@media (max-width: 900px)": {
      fontSize: "12px !important",
    },
    "@media (max-width: 700px)": {
      fontSize: "10px !important",
    },
    "@media (max-width: 585px)": {
      fontSize: "5px !important",
    },
    "@media (max-width: 495px)": {
      fontSize: "6px !important",
    },
    "@media (max-width: 600px)": {
      fontSize: "6px !important",
      justifyContent: "space-around",
      paddingBottom: "2%",
    },
  },
});

const initialValues = { email: "" };

const validationSchema = Yup.object().shape({});

export default function Index() {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [services, setServices] = useState([]);
  const [policy, setPolicy] = React.useState({});

  const serviceFun = () => {
    API.getAllServices().then((res) => {
      setServices(res?.data?.data);
    });
  }

  const contactFun = () => {
    API.getAllContacts().then((res) => {
      setData(res.data?.data);
    });
  }

  const policyFun = () => {
    API.policies().then((res) => {
      setPolicy(res.data?.data[0])
    })
  }

  useEffect(() => {
    contactFun();
    serviceFun();
    policyFun();
  }, []);

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  function onSubmit(values, { resetForm }) {
    if (values.email !== '') {
      const formData = new FormData();
      formData.append("email", values?.email);
      API.addSubscriber(formData).then(() => {
        resetForm();
      })
    }
  }

  return (
    <Container maxWidth="xl">
      <Box
        mt={7}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          background: "white !important",
        }}
        className={classes.main}
      >
        <Grid
          container
          sx={{
            pl: { lg: 14, md: 7, sm: 2, xs: 9, xl: 5 },
            pr: { lg: 14, md: 7, sm: 2, xs: 2, xl: 5 },
            paddingLeft: { xl: 20 },
            paddingRight: { xl: 10 },
          }}
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            sx={{ pb: { xs: 1, lg: 6 } }}
          >
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{ pb: { xs: 2, md: 0, lg: 0 } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: 24, xs: 19, md: 24 },
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    pb: 1,
                    color: "#8300f8 !important",
                  }}
                >
                  SERVICES
                </Typography>
                {services && services.length > 0
                  ? services?.map((service, index) => {
                    return (
                      <Typography sx={{ my: 1 }} key={index} className={classes.subheadingL}>
                        {service.title}
                      </Typography>
                    );
                  })
                  : null}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography
                  sx={{
                    fontSize: { sm: 24, xs: 16, md: 24 },
                    fontWeight: "bold",
                    fontFamily: "'Montserrat', sans-serif",
                    pb: 1,
                    color: "#8300f8 !important",
                  }}
                >
                  CONTACT US
                </Typography>
                <Box
                  display={"flex"}
                  sx={{ padding: { md: "10px 0", sm: "2px 0", xs: "8px 0px" } }}
                >
                  <Box
                    sx={{
                      width: { xs: 20, sm: 20, md: 20 },
                      height: { xs: 6, sm: 20, md: 20 },
                      mr: { md: 2, sm: 2, xs: 2 },
                    }}
                  >
                    <img src={location} style={{ objectFit: "fit" }} alt="" loading="lazy" />
                  </Box>
                  <Typography
                    sx={{ color: "black !important" }}
                    className={classes.subheadingL}
                  >
                    {data?.address1}
                  </Typography>
                </Box>
                {data?.address2 && (
                  <Box
                    display={"flex"}
                    sx={{ padding: { md: "10px 0", sm: "2px 0", xs: "8px 0px" } }}
                  >
                    <Box
                      sx={{
                        width: { xs: 20, sm: 20, md: 20 },
                        height: { xs: 6, sm: 20, md: 20 },
                        mr: { md: 2, sm: 2, xs: 2 },
                      }}
                    >
                      <img src={location} style={{ objectFit: "fit" }} alt="" loading="lazy" />
                    </Box>
                    <Typography
                      sx={{ color: "black !important" }}
                      className={classes.subheadingL}
                    >
                      {data?.address2}
                    </Typography>
                  </Box>
                )}
                <Box
                  display={"flex"}
                  sx={{ padding: { md: "10px 0", sm: "2px 0", xs: "8px 0px" } }}
                >
                  <Box
                    sx={{
                      width: { xs: 20, sm: 20, md: 20 },
                      height: { xs: 17, sm: 20, md: 20 },
                      mr: { md: 2, sm: 2, xs: 2 },
                    }}
                  >
                    <img src={call} width={"100%"} height={"100%"} alt="" loading="lazy" />
                  </Box>
                  <Typography
                    sx={{ pt: { xs: 0.5, sm: 0 } }}
                    className={classes.subheadingL}
                  >
                    {data?.number}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"flex-center"}
                  sx={{ padding: { md: "10px 0", sm: "2px 0", xs: "8px 0px" } }}
                >
                  <Box
                    sx={{
                      width: { xs: 20, sm: 20, md: 20 },
                      height: { xs: 17, sm: 20, md: 20 },
                      mr: { md: 2, sm: 2, xs: 2 },
                    }}
                  >
                    <img src={msg} width={"100%"} height={"100%"} alt="" loading="lazy" />
                  </Box>
                  <a className={classes.subheadingL}
                    style={{ pt: { xs: 0.5, sm: 0 }, textDecoration: 'none' }} href={`mailto:${data?.email}`}>
                    {data?.email}
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={10}
            xl={12}
            sx={{ pb: { xs: 1, lg: 4 } }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.mediaIcons}
              >
                <Box sx={{ width: { md: 80, sm: 80, xs: 40 } }} onClick={() => window.open(data?.facebook)}>
                  <img style={{ cursor: 'pointer' }} src={fb} alt="facebook" width={"100%"} loading="lazy" />
                </Box>
                <Box sx={{ width: { md: 80, sm: 80, xs: 40 } }} onClick={() => window.open(data?.instagram)}>
                  <img style={{ cursor: 'pointer' }} src={insta} alt="instagram" width={"100%"} loading="lazy" />
                </Box>
                <Box sx={{ width: { md: 80, sm: 80, xs: 40 } }} onClick={() => window.open(data?.linked_in)}>
                  <img style={{ cursor: 'pointer' }} src={linkdein} alt="youtube" width={"100%"} loading="lazy" />
                </Box>
                <Box sx={{ width: { md: 80, sm: 80, xs: 40 } }} onClick={() => window.open(data?.twitter)}>
                  <img style={{ cursor: 'pointer' }} src={twitter} alt="youtube" width={"100%"} loading="lazy" />
                </Box>
              </Grid>
              <Grid
                item
                xs={9}
                sm={6}
                md={6}
                lg={6}
                sx={{ width: { md: 400, sm: 250, xs: 150 }, pl: { xs: 2 } }}
              >
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <OutlinedInput
                    placeholder="Your Email Address"
                    type="email"
                    name="email"
                    value={formik.values["email"]}
                    onChange={formik.handleChange}
                    sx={{
                      border: "1px solid grey",
                      width: { xs: "100%", lg: "100%", xl: "85%" },
                      borderRadius: "41px",
                      height: { xs: "30px", sm: "40px", md: "50px" },
                      fontSize: { xs: "6px", sm: "10px", md: "14px" },
                      fontWeight: "bold",
                      outline: "none",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          type="submit"
                          sx={{
                            textTransform: "none",
                            backgroundColor: "black",
                            color: "white",
                            padding: {
                              sm: "12px 17px",
                              xs: "7px 0px",
                              md: "12px 30px",
                            },
                            mr: -2,
                            width: { xs: 80, sm: 100, md: 160, lg: 160, xl: 180 },
                            fontSize: { xs: "9px", sm: "10px", md: "15px" },
                            fontWeight: "bold",
                            borderRadius: "41px",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                          edge="end"
                        >
                          Subscribe
                        </Button>
                      </InputAdornment>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              sx={{ width: "100%", margin: "auto", mb: "20px" }}
            >
              <Grid
                item
                md={9}
                xs={12}
                sm={9}
                display={"flex"}
                className={classes.links}
              >
                <Link
                  href="/about"
                  color={"black"}
                  underline="none"
                >
                  About us |&nbsp;
                </Link>
                <Link
                  target={"_blank"}
                  href={policy.terms_conditions}
                  color={"black"}
                  underline="none"
                >
                  Terms & conditions |&nbsp;
                </Link>
                <Link
                  target={"_blank"}
                  href={policy.cookie_policy}
                  color={"black"}
                  underline="none"
                >
                  Cookies Policy |&nbsp;
                </Link>
                <Link
                  target={"_blank"}
                  href={policy.privacy_policy}
                  color={"black"}
                  underline="none"
                >
                  Privacy Policy |&nbsp;
                </Link>
                <Link
                  target={"_blank"}
                  href={policy.recruitment_policy}
                  color={"black"}
                  underline="none"
                >
                  Recruitment Policy
                </Link>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sm={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.links} fontWeight={"bold"}>
                  @ Geeklone Technology Ltd.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
