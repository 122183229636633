import React, { useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";

import clsx from "clsx";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const BootstrapButton = styled(Button)({
  textTransform: "none",

  lineHeight: 0,
  color: "#8300F8",
  backgroundColor: "white",
  borderColor: "#8300F8",
  borderRadius: 30,

  "&:hover": {
    background: "white",
  },
});

const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`,
    background: "black",
    color: "white",
  },

  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translateX(-320%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0%)",
    },
  },
}));

const GeneralBtn = ({ title, paddingL, paddingR, marginL, type, fun, width }) => {
  const [show, setShow] = useState(false);
  const classes = useStyles();

  return (
    <>
      {show ? (
        <BootstrapButton
          type={type ? type : 'button'}
          style={{ color: "black" }}
          sx={{
            fontSize: { lg: "17px", md: "15px", sm: "13px", xs: "13px" },
            fontWeight: "900",
            textAlign: "center",
            width: { lg: width, xs: "160px" },
            pl: paddingL,
            pr: paddingR,
            py: "5px",
          }}
          variant="contained"
          disableRipple
          onMouseOver={() => setShow(true)}
          onMouseOut={() => setShow(false)}
          onClick={fun}
          endIcon={
            <ArrowForwardIosIcon
              className={clsx(classes.animatedItem)}
              style={{
                fill: "white",
                background: "black",
                borderRadius: "50%",
                fontSize: "40px",
                padding: "5px",
                marginLeft: marginL,
              }}
            />
          }
        >
          {title}
        </BootstrapButton>
      ) : (
        <BootstrapButton
          variant="contained"
          sx={{
            fontSize: { lg: "17px", md: "15px", sm: "13px", xs: "13px" },
            fontWeight: "1000",
            textAlign: "center",
            width: { lg: width, xs: "160px" },
            pl: paddingL,
            pr: paddingR,
            py: "5px",
          }}
          disableRipple
          onMouseOver={() => setShow(true)}
          onMouseOut={() => setShow(false)}
          endIcon={
            <ArrowForwardIosIcon
              style={{
                fill: "white",
                background: "#FDA837",
                borderRadius: "50%",
                fontSize: "40px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginLeft: marginL,
              }}
            />
          }
        >
          {title}
        </BootstrapButton>
      )}
    </>
  );
};

export default GeneralBtn;
