const flutter =
  "https://drive.google.com/uc?export=view&id=1f9Yv3ofnStPKQZWm2tdl6gFPUAnPKtq1";
const apple =
  "https://drive.google.com/uc?export=view&id=1leU3Us-QgM87XvtBetlhzLGyXuk0J_8U";
const ionic =
  "https://drive.google.com/uc?export=view&id=1d92vmRHeCo2yN062YiFntR63m4_49wpZ";
const java =
  "https://drive.google.com/uc?export=view&id=1cPuT1RS8ICLR-8g8vZG5neHj5ZGWm1qI";
const kotlin =
  "https://drive.google.com/uc?export=view&id=1JD6P7iGNyODhHt7i8AxHgW4LmiMpa2Oh";
const php =
  "https://drive.google.com/uc?export=view&id=1J7Ax5Fgj8TXRQSiJa6MNLjikZyOlqN3I";
const android =
  "https://drive.google.com/uc?export=view&id=1go3hNunfacP0Mejw9Xq-zN7B_8FDA1tp";
const node =
  "https://drive.google.com/uc?export=view&id=1fwjhSQgBpoRlw-cI73OCogmRiTaIX9-5";
const react =
  "https://drive.google.com/uc?export=view&id=1DwfKo8i1G65_wUQI7xQVU4IfXXwZEyOL";
const swift =
  "https://drive.google.com/uc?export=view&id=19q7GIQVsfjW2TJR8nKjfaW9P2Uo60mc8";
const AboutH =
  "https://drive.google.com/uc?export=view&id=1pT_335yBRjCO_cnicQinnFXZl5yC61GS";
const aboutPath =
  "https://drive.google.com/uc?export=view&id=1KCxsmzPvHDQCqQDF5NUt8B2vJ99OK8iq";
const discussProjectBg =
  "https://drive.google.com/uc?export=view&id=1I4P52dVveQ0W_J9V6VwgUphGHTlCH7ff";


export {
  discussProjectBg,
  flutter,
  apple,
  ionic,
  java,
  kotlin,
  php,
  android,
  node,
  react,
  swift,
  AboutH,
  aboutPath,
};
