import React, { useState, useEffect } from "react";

import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import logo from "../../assets/images/logo.png";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Grid } from "@mui/material";
const logo =
  "https://drive.google.com/uc?export=view&id=15MG-O5XC4r7-hlUQ_E0jjteBP-5Wt_PH";

const navigationLinks = [
  { name: "HOME", to: "/" },
  { name: "ABOUT US", to: "/about" },
  { name: "OUR SERVICES", to: "/services" },
  { name: "PORTFOLIO", to: "/ourWork" },
  { name: "INDUSTRIES", to: "/industries" },
  { name: "TESTIMONIAL", to: "/testimonial" },
];

const useStyles = makeStyles((theme) => ({
  desk: {
    padding: "0px 14px",
    color: "white",
    listStyle: "none",
    textDecoration: "none",
    fontWeight: 600,
    fontFamily: "calbiri , sans-serif !important",
    cursor: "pointer",
    "&:hover": { color: "#FDA837" },
    fontSize: "14px",
    "@media (min-width: 954px) and (max-width: 998px)": {
      fontSize: "12px",
    },
  },
  desk2: {
    color: "#FDA837",

    padding: "20px 14px",
    fontWeight: 600,
    marginTop: "-5px",
    fontFamily: "calbiri , sans-serif !important",
    cursor: "pointer",
    "&:hover": { color: "#FDA837" },
    fontSize: "14px",
    "@media (min-width: 954px) and (max-width: 998px)": {
      fontSize: "12px",
    },
  },
  btnhover: {
    background: "#FDA837 !important",
    "&:hover": { background: "#FDA837 !important" },
  },
  btnhover2: {
    "&:hover": { background: "#FDA837 !important" },
  },
  navcolor: {
    color: "#FDA837",
  },

  navBg: {
    background: "#8300F8",
    color: "white",
    border: "none",
    boxShadow: "0px 0px 0px 0px",
    transition: "background 900ms ease-in-out",
    zIndex: 100,
  },
  navBg2: {
    background: "transparent",
    boxShadow: "0px 0px 0px 0px",
    border: "none",
    transition: "background 900ms ease-in-out",
    zIndex: 100,
  },
  checkAvt: {
    height: 90,
    width: 90,
  },
  mob: {
    color: "#FDA837",
    fontSize: 15,
    fontWeight: 600,
    marginTop: "-8px",
    fontFamily: "calbiri , sans-serif !important",
    cursor: "pointer",
    "&:hover": { color: "#FDA837" },
  },
  mob2: {
    color: "black",
    fontSize: 15,
    fontWeight: 600,
    fontFamily: "calbiri , sans-serif !important",
    cursor: "pointer",
    "&:hover": { color: "#FDA837" },
  },
  fontF: {
    fontFamily: "calbiri",
  },
}));

const Navbar = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);
  useEffect(() => {
    changeBackground();

    window.addEventListener("scroll", changeBackground);
  }, []);
  let location = useLocation();
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ color: 'red' }}
        className={navbar ? classes.navBg : classes.navBg2}
      >
        <Toolbar>
          <Container maxWidth="xl" >
            <Grid container>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    px: { lg: 10, md: 10, sm: 2, xs: 2 },
                  }}
                >
                  <Avatar className={classes.checkAvt} src={logo} loading="lazy" />
                </Box>
              </Grid>
              <Grid
                item
                md={9}
                sm={9}
                xs={9}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: { lg: 15, md: 5, sm: 1, xs: 1 },
                  }}
                >
                  <Hidden smDown>
                    {navigationLinks.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Link
                            className={
                              location.pathname === item.to
                                ? classes.desk2
                                : classes.desk
                            }
                            onClick={() => {
                              navigate(item.to);
                            }}
                            variant="button"
                            underline="none"
                          >
                            {item.name}
                          </Link>

                          <Box
                            display={
                              location.pathname === item.to ? "block" : "none"
                            }
                            sx={{
                              width: "7px",
                              height: "7px",
                              borderRadius: "50%",
                              background: "#FDA837",
                              mt: "-13px",
                            }}
                          >
                            {" "}
                          </Box>
                        </Box>
                      );
                    })}

                    <Button
                      className={
                        location.pathname === "/ContactUs"
                          ? classes.btnhover
                          : classes.btnhover2
                      }
                      mddown={{
                        size: "small",
                      }}
                      variant="outlined"
                      style={{
                        color: "white",
                        border: "1px solid #c78ee2",
                        borderRadius: "20px",
                        fontFamily: "calbiri , sans-serif !important",
                      }}
                      onClick={() => {
                        navigate("/ContactUs");
                      }}
                    >
                      CONTACT US
                    </Button>
                  </Hidden>
                  <Hidden mdUp>
                    <IconButton onClick={() => setOpen(true)}>
                      <MenuIcon style={{ color: "white" }} />
                    </IconButton>
                  </Hidden>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>

        <SwipeableDrawer
          anchor="right"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          <div
            onClick={() => setOpen(false)}
            onKeyPress={() => setOpen(false)}
            role="button"
            tabIndex={0}
          >
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {navigationLinks.map((item, index) => (
              <ListItem key={index}>
                <Link
                  className={
                    location.pathname === item.name ? classes.mob : classes.mob2
                  }
                  onClick={() => {
                    navigate(item.to);
                    setOpen(false);
                  }}
                  onKeyPress={() => setOpen(false)}
                  variant="button"
                  underline="none"
                >
                  {item.name}
                </Link>
              </ListItem>
            ))}
          </List>
          <Button
            variant="outlined"
            style={{
              color: "white",
              border: "1px solid white",
              borderRadius: "20px",
              fontFamily: "calbiri",
            }}
            onClick={() => {
              navigate("/ContactUs");
            }}
          >
            CONTACT US
          </Button>
        </SwipeableDrawer>
      </AppBar>
    </>
  );
};

export default Navbar;
