/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { API } from "../../api";
import { makeStyles } from "@material-ui/core";
import { Container, Grid } from "@mui/material";

const useStyles = makeStyles(() => ({
  detail: {
    "@media (min-width: 600px)": {
    },
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const [slider, setSlider] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [slides, maxSlides] = useState(0);

  const getProminentProjects = () => {
    let type = 'prominent';
    API.getAllProjects(type).then((res) => {
      setSlider(res?.data?.data?.data);
      maxSlides(res?.data?.data?.data?.length);
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderIndex + 1 === slides) setSliderIndex(0);
      else setSliderIndex(sliderIndex => sliderIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides, sliderIndex]);

  useEffect(() => getProminentProjects(), [])

  return (
    <Box sx={{ background: "#F8F8F8" }}>
      <Container maxWidth={'xl'}>
        <Box
          sx={{
            mt: { lg: 3, xs: 12 },
            mb: { lg: 5, xs: 9 },
            maxWidth: "100%",
            height: { xs: "100%", md: "500px" },
          }}
          display="flex"
          justifyContent={'space-around'}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }} >
                <Box
                  component="img"
                  sx={{
                    transition: "all 0.2s ease-in-out",
                    display: "block",
                    maxWidth: 400,
                    overflow: "hidden",
                    width: "100%",
                    objectFit: "cover",
                    pb: { lg: 3 },
                  }} loading="lazy"
                  src={slider[sliderIndex]?.image}
                  alt={slider[sliderIndex]?.description}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box sx={{
                maxWidth: { xs: '100%', sm: '90%', md: "70%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "25px",
                      md: "20px",
                      sm: "18px",
                      xs: "16px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  {slider[sliderIndex]?.title}
                </Typography>
                <Typography
                  className={classes.detail}
                  sx={{
                    fontSize: { md: "17px", sm: "16px", xs: "13px" },
                    color: "gray",
                    minHeight: { xs: "50px" },
                  }}
                >
                  {slider[sliderIndex]?.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
