import React from "react";
import Card4 from "./Card4";

const awesomePaintBrush =
  "https://drive.google.com/uc?export=view&id=1gI8fNdrteZOYHGZ1mnNtil31GC2bmv_Z";
const iosBug =
  "https://drive.google.com/uc?export=view&id=1mw0xHV_VY2G2hFh2mJKmms795XA9BuRj";
const materialDeveloperMode =
  "https://drive.google.com/uc?export=view&id=1x1VpwHi6HbwK6o2GO_yUcFgL8gugWENf";
const openLightBulb =
  "https://drive.google.com/uc?export=view&id=1LMv1DxlA6CUlLy-VUHzOjK7RQlAsiSpD";

const cards1 = [
  {
    icon: openLightBulb,
    title: "Idea Consultation",
  },

  {
    icon: awesomePaintBrush,
    title: "Application Design",
  },
  {
    icon: materialDeveloperMode,
    title: "Application Development",
  },
  {
    icon: iosBug,
    title: "Application Testing",
  },
];

function index() {
  return <Card4 cards={cards1} />;
}

export default index;
