import axios from "../axiosConfig";

class Routes {
  // get why choose us
  getChoose(check) {
    return axios.get(`/web/get-why-choose-us?is_highlight=${check}`);
  }

  //All Technologies
  getAllTechnologies() {
    return axios.get("/web/getAllTechnologies");
  }

  // Get Clients Reviews
  getReviews(page) {
    return axios.get(`/web/testimonials?page=${page}`);
  }

  //All Reviews
  getAllReviews() {
    return axios.get("/web/getAllReviews?type=prominent");
  }

  //All Services
  getAllServices() {
    return axios.get("/web/getAllServices");
  }

  //industry
  getAllIndustries(page) {
    return axios.get(`/web/getAllIndustries?page=${page}`);
  }

  // Get Contacts
  getAllContacts() {
    return axios.get("/web/getAllContacts");
  }

  //WORK portfolio
  getAllProjects(type, page, id) {
    return axios.get(`/web/getAllProjects?type=${type}&id=${id}&page=${page}`);
  }

  // Contact us form email
  sendMail(data) {
    return axios.post("/web/contactUs", data);
  }

  // Descriptions
  description(screen) {
    return axios.get(`/web/description?screen=${screen}`);
  }

  //MEET us TEAM
  getAllMembers() {
    return axios.get("/web/getAllMembers");
  }

  // Domains
  domains() {
    return axios.get("/web/list");
  }

  // Policies
  policies() {
    return axios.get("/web/policies");
  }

  // addSubscriber
  addSubscriber(data) {
    return axios.post("/web/subscribe", data)
  }

  // WE VAlue
  getValues() {
    return axios.get("/web/get-values");
  }

  verifyEmail(query) {
    return axios.get(`/web/verify-email?query=${query}`)
  }

  unSubEmail(query) {
    return axios.get(`/web/un-subscribe-email?query=${query}`)
  }
}

export default new Routes();
