import React, { lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Loading from "../components/Loader/Loader";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import ProjectDetails from "../screens/projectDetails/projectDetails";
import Footer from "../components/Footer/index";

const Home = lazy(() => import("../screens/Home/Home"));
const AboutUs = lazy(() => import("../screens/AboutUs/AboutUs"));
const Services = lazy(() => import("../screens/Services/Services"));
const OurWork = lazy(() => import("../screens/OurWork/OurWork"));
const Industries = lazy(() => import("../screens/Industries/Industries"));
const PrivacyPolicy = lazy(() => import("../screens/PrivacyPolicy/PrivacyPolicy"));
const ContactUsPage = lazy(() => import("../screens/ContactUs/ContactUsPage"));
const TestimonialScreen = lazy(() => import("../screens/Testimonial/TestimonialScreen"));
const VerifyEmail = lazy(() => import("../screens/General/VerifyEmail"));
const UnSubscribe = lazy(() => import("../screens/General/UnSubscribe"));

export default function Index() {

  const [urlPath, setUrlPath] = React.useState("");

  const url = useLocation().pathname;

  React.useEffect(() => {
    const parts = url.split('/');
    setUrlPath(parts[1])
  }, [url])

  return (
    <React.Suspense fallback={<Loading />}>
      <ScrollToTop />
      {urlPath !== "policy" && (<Navbar />)}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/ourWork" element={<OurWork />} />
        <Route exact path="/projectDetails" element={<ProjectDetails />} />
        <Route exact path="/ContactUs" element={<ContactUsPage />} />
        <Route exact path="/industries" element={<Industries />} />
        <Route exact path="/testimonial" element={<TestimonialScreen />} />
        <Route exact path="/policy/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/un-subscribe-email" element={<UnSubscribe />} />
      </Routes>
      {urlPath !== "policy" && (<Footer />)}

    </React.Suspense>
  );
}
