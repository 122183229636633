import React from "react";
import { useLocation } from "react-router-dom";
import ProjectDetail from "./ProjectDetail";

const Index = () => {
  const { state } = useLocation();
  return <ProjectDetail arr={state} />;
};

export default Index;
