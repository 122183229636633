import "./App.css";
import Router from "./routes/index";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  transition: Slide,
  color: "#343a40",
  minHeight: "60px",
  borderRadius: "8px",
  background: "#2FEDAD",
  boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3)",
};

function App() {
  return (
    <>
      <ToastContainer {...toastOptions} />
      <Router />
    </>
  )
}

export default App;
