import React from "react";
import "./loaderStyle.css";
import { Box } from "@mui/material";

export default function Loading() {
  return (
    <Box sx={{ mt: "40vh" }}>
      <Box component="div" className={"container"}>
        <Box component="div" className={"loader"}>
          <Box className="spanClass"></Box>
        </Box>
      </Box>
    </Box>
  );
}
