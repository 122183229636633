import React from "react";

import DiscussProject from "../../components/DiscussProject/DiscussProject";

import ContactUs from "../../components/ContactUs/ContactUs.js";
import WorkPortfolio from "../../components/WorkPortfolio/WorkPortfolio";

import WhatWeDo from "../../components/Card/card4/index";
import ProjectDetail from "../../components/ProjectDetail/index";

export default function ProjectDetails() {
  return (
    <>
      <ProjectDetail />
      <DiscussProject />
      <WhatWeDo />
      <WorkPortfolio />
      <ContactUs />
    </>
  );
}
